<template>
  <div class="fullScreen show" v-loading="loading">
    <Crumbs>
      <el-breadcrumb-item slot="after"> 商务团队分工 </el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain">
      <!-- <div class="fr p_b">
                    <el-button type="primary" icon="el-icon-plus" @click="add_user()">新增</el-button>
                </div> -->

      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-tabs v-model="activeName" class="fullScreenMainHeader">
            <el-tab-pane name="0" label="商务团队分工"></el-tab-pane>
            <el-tab-pane name="1" label="基本信息" v-if="options.id"></el-tab-pane>
            <el-tab-pane name="2" label="附件" v-if="options.id"></el-tab-pane>
            <el-tab-pane name="3" label="商务追踪" v-if="options.id"></el-tab-pane>
            <el-tab-pane name="4" label="商务绩效" v-if="options.id"></el-tab-pane>
          </el-tabs>
          <div class="fr p_b" v-if="activeName == 0">
            <el-button type="primary" icon="el-icon-plus" @click="add_user()">新增</el-button>
          </div>
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            v-if="activeName == 0"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" type="index" label="序号"></el-table-column>
            <el-table-column align="center" prop="userName" label="姓名"></el-table-column>
            <el-table-column align="center" prop="deptNames" label="部门"></el-table-column>
            <el-table-column align="center" prop="postName" label="岗位"></el-table-column>
            <el-table-column align="center" prop="phone" label="联系电话"></el-table-column>
            <el-table-column align="center" width="280" prop="principalUserType" label="主次负责人">
              <template slot-scope="scope">
                <el-radio-group v-model="scope.row.principalUserType">
                  <el-radio-button label="主要负责人"></el-radio-button>
                  <el-radio-button label="次要负责人"></el-radio-button>
                </el-radio-group>
              </template>
            </el-table-column>

            <el-table-column prop="title" align="center" label="操作">
              <template slot-scope="scope">
                <el-button type="text" size="small" class="text_Remove_Bgc" @click.stop="del(scope)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <Manage width="600px" :BusinessId="options.id" v-show="activeName == 1"></Manage>
        <Annex :manageId="options.id" :file_Data="file_Data" v-show="activeName == 2"></Annex>
        <Business width="600px" :ManageId="options.id" v-show="activeName == 3"></Business>
        <DistriBution
          width="600px"
          :options="{ businessManageId: options.id }"
          :Disabled="true"
          v-if="activeName == 4"
        ></DistriBution>
      </div>

      <div class="fullScreenOperation">
        <el-button type="success" plain v-show="activeName == 0" @click="save">保存</el-button>
        <el-button type="info" @click="re">返回</el-button>
      </div>
    </div>

    <EditDialog width="600px" :isShow.sync="showEdit" @submit="submit">
      <div slot="title" class="title">新增指派负责人</div>
      <div slot="content" class="content">
        <el-tree
          ref="principalUserDataTree"
          show-checkbox
          :load="loadNode"
          :lazy="true"
          :default-expand-all="false"
          :expand-on-click-node="false"
          :check-on-click-node="true"
          :props="{
            children: 'zones',
            label: 'label',
            isLeaf: 'leaf',
          }"
        >
          <div class="custom-tree-node" slot-scope="{ node, data }">
            <template v-if="data.type == 'dept'">
              <i class="iconfont iconcompany" style="color: #399ffb; margin-right: 0px"></i>
              {{ data.deptId | cascader(deptData, 'id', 'deptName') }}
            </template>
            <template v-else>
              <i class="iconfont iconbussiness-man" style="color: #399ffb; margin-right: 0px"></i>
              {{ data.loginName }}
            </template>
          </div>
        </el-tree>
      </div>
    </EditDialog>
  </div>
</template>
<script>
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Business: () => import('@/views/publicView/Business.vue'),
    Assign: () => import('@/views/publicView/Assign.vue'),
    Annex: () => import('@/views/publicView/Annex.vue'),
    Manage: () => import('@/views/publicView/Manage.vue'),
    DistriBution: () => import('@/views/publicView/DistriBution.vue'),
  },

  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  data() {
    return {
      // parameter:{
      //     pageNow:1,
      //     pageSize:50,
      //     total:0,
      // },
      activeName: 0,
      showEdit: false,
      loading: false,
      submitLoading: false,
      form: {},
      type: 'portrait',
      tableData: [],
      editDialogLoading: false,
      del_table: [],
      UserData: [],
      deptData: [],
      file_Data: [],
    }
  },
  inject: ['re'],
  mounted() {},
  watch: {
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        if (newVal.id) {
          this.getData()
          this.getUser()
        }
      },
    },
  },
  created() {},
  methods: {
    getData() {
      this.loading = true
      this.tableData = []

      this.$api.businessManage
        .getUserAssignment(this.options.id)
        .then(res => {
          this.loading = false
          this.del_table = []
          if (res.data.principalUser == null) {
            this.tableData = []
          } else {
            let arr = res.data.principalUser
            let new_arr = []
            arr.forEach(e => {
              if (e.userType == '负责人') {
                new_arr.push(e)
              }
            })
            this.tableData = new_arr
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })

      this.$api.businessManage
        .getBusinessManage(this.options.id)
        .then(res => {
          this.editDialogLoading = false

          let arr = []
          if (res.data.businessFileList.length > 0) {
            for (let i = 0; i < res.data.businessFileList.length; i++) {
              const e = res.data.businessFileList[i]
              e.attachmentName = e.fileName
              e.attachmentPath = e.filePath
              arr.push(e)
            }
          }
          this.file_Data = arr
        })
        .catch(err => {
          this.editDialogLoading = false
          console.log(err)
        })
    },
    add_user() {
      this.form = {}
      this.showEdit = true
    },
    del(scope) {
      this.tableData.splice(scope.$index, 1)
      if (scope.row.id) {
        this.del_table.push(scope.row.id)
      }
    },
    submit() {
      var arr = this.$refs.principalUserDataTree.getCheckedNodes(true)
      let a = {}
      arr.forEach(e => {
        a = {}
        a.userType = '负责人'
        a.businessId = this.options.id
        ;(a.staffId = e.id), (a.postName = e.postName)
        a.deptNames = e.deptNames
        ;(a.userName = e.userName),
          (a.phone = e.phone),
          (a.principalUserType = '主要负责人'),
          this.tableData.push(a)
      })
      this.showEdit = false
    },
    save() {
      let arr = []
      for (let i = 0; i < this.tableData.length; i++) {
        const e = this.tableData[i]
        arr.push({
          id: e.id,
          staffId: e.staffId,
          businessId: this.options.id,
          userType: '负责人',
          principalUserType: e.principalUserType,
        })
      }

      let obj = {
        userAssignmentRequst: arr,
        deleteUserAssignmentList: this.del_table,
      }

      this.$api.businessManage
        .addUserAssignment(obj)
        .then(res => {
          this.loading = false
          this.del_table = []
          this.$message.success('操作成功！')
          this.re()
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    getUser() {
      this.$api.sysDept.listDept({}).then(res => {
        this.deptData = res.data
        this.UserData = []
        res.data.some(r => {
          this.UserData.push({
            deptId: r.id,
            label: '',
            icon: 'iconcompany',
            type: 'dept',
            disabled: true,
          })
        })
      })
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        resolve(this.UserData)
      } else {
        if (node.data.type == 'dept') {
          this.$api.user
            .listStaffNotPage({
              deptId: node.data.deptId,
            })
            .then(res => {
              var arr = []
              res.data.some(r => {
                arr.push(
                  r.combination({
                    leaf: true,
                    deptId: node.data.deptId,
                  })
                )
              })
              resolve(arr)
            })
            .catch(err => {})
        } else {
          resolve([])
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/edit.scss';
.p_t_35 {
  padding-top: 35px;
}
.p_b {
  padding-bottom: 25px;
}
.el-table {
  height: calc(100vh - 347px);
}
</style>
